import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { ActionType } from './action-types';

export const store = createStore(reducers, {}, applyMiddleware(thunk));

store.dispatch({
  type: ActionType.INSERT_CELL_AFTER,
  payload: {
    id: null,
    type: 'code',
  },
});

store.dispatch({
  type: ActionType.INSERT_CELL_AFTER,
  payload: {
    id: null,
    type: 'code',
  },
});

store.dispatch({
  type: ActionType.INSERT_CELL_AFTER,
  payload: {
    id: null,
    type: 'text',
  },
});

store.dispatch({
  type: ActionType.UPDATE_CELL,
  payload: {
    id: store.getState().cells.order[0],
    content: `# Welcome to JBook
* Code in editors are combined cumulatively, so code from an upper editor is visible to a lower editor.
* You can add more text or code blocks by hovering your cursor between blocks and you can find block controls in the top right corner.
* Clicking on a text block opens up a markdown editor, clicking outside of the editor closes the editor and only shows the preview.
* You can use any package found in NPM by simply importing in the editor, for example \`import React from 'react'\` and it will be automatically fetched and bundled for use.
* All preview windows have an element with id \`root\` in them
* You can use \`show();\` function to show primitives, objects and React components.

### For example
\`\`\`
show(123);
show('abc');
show(<h1>Hello World</h1>);
show(<MyComponent />);
\`\`\``,
  },
});

store.dispatch({
  type: ActionType.UPDATE_CELL,
  payload: {
    id: store.getState().cells.order[1],
    content: `const hello = 'Hello world';
show(hello);`,
  },
});

store.dispatch({
  type: ActionType.UPDATE_CELL,
  payload: {
    id: store.getState().cells.order[2],
    content: `import React from 'react';
import ReactDOM from 'react-dom';

const App = () => {
  return (
    <div>
      <h1>Hello world</h1>
    </div>
  );
};

show(<App />);`,
  },
});
